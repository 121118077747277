import React, { FC, useMemo } from 'react';
import { Calendar, momentLocalizer, Views, View, Formats, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomEvent, { MyEvent } from './components/CustomEvent/CustomEvent';
import eventPropGetter from './components/eventPropGetter';
import DesktopCalendar from './components/DesktopCalendar/DesktopCalendar';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import MobileCalendar from './components/MobileCalendar/MobileCalendar';

moment.updateLocale('en', {
    week: {
        dow: 1, 
    },
});

const localizer: DateLocalizer = momentLocalizer(moment);

// Определяем тип для пропсов, которые будет принимать MyCalendar
interface MyCalendarProps {
    events: MyEvent[]; // Массив событий типа MyEvent
}

// Определяем форматы для отображения времени в 24-часовом формате
const formats: Formats = {
    timeGutterFormat: 'HH:mm', // Формат для отображения времени по вертикали
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        `${localizer?.format(start, 'HH:mm', culture)} - ${localizer?.format(end, 'HH:mm', culture)}`, // Безопасный доступ к localizer с ?
    agendaTimeFormat: 'HH:mm' // Формат для времени в представлении "agenda"
};


interface MyCalendarProps {
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    events: MyEvent[];
    onEventClick: (myEvent: MyEvent) => void;
    isCalendarMobile: boolean
}

const MyCalendar: FC<MyCalendarProps> = ({
    view,
    currentDate,
    setCurrentDate,
    events,
    onEventClick,
    isCalendarMobile
}) => {
    const startOfWeek = moment(currentDate).startOf('week').toDate();
    const endOfWeek = moment(currentDate).endOf('week').toDate();
    const width = useWindowWidth();
    const filteredEvents = useMemo(() => {
        if (view === Views.WEEK) {
            return events.filter(event => {
                if (!event.start || !event.end) {
                    return false; // Пропускаем события без start или end
                }

                const eventStart = new Date(event.start);
                const eventEnd = new Date(event.end);
                
                return (
                    (eventStart >= startOfWeek && eventStart <= endOfWeek) ||
                    (eventEnd >= startOfWeek && eventEnd <= endOfWeek) ||
                    (eventStart <= startOfWeek && eventEnd >= endOfWeek) // для событий, которые охватывают всю неделю
                );
            });
        }
        return events; // Если не недельный вид, возвращаем все события
    }, [events, view, startOfWeek, endOfWeek]);

    function adjustTimeSlotWidth() {
        const headerGutter = document.querySelector('.rbc-label.rbc-time-header-gutter') as HTMLElement;
        const timeSlots = document.querySelectorAll('.rbc-time-slot');

        if (headerGutter && timeSlots.length > 0) {
            const gutterWidth = window.getComputedStyle(headerGutter).width;
            timeSlots.forEach(slot => {
                const slotElement = slot as HTMLElement; 
                slotElement.style.width = gutterWidth;
                slotElement.style.minWidth = gutterWidth;
                slotElement.style.maxWidth = gutterWidth;
            });
        }
    }

    window.addEventListener('load', adjustTimeSlotWidth);
    window.addEventListener('resize', adjustTimeSlotWidth);


    return (
        <div style={{ height: '100vh' }}>
            {!isCalendarMobile ?
                <DesktopCalendar
                    localizer={localizer}
                    filteredEvents={filteredEvents} // Используем отфильтрованные события
                    view={view}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    onEventClick={onEventClick}
                /> :
                <MobileCalendar
                    events={filteredEvents} // Используем отфильтрованные события
                    view={view}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    onEventClick={onEventClick}
                />

            }
        </div>
    );
};

export default MyCalendar;
