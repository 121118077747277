import { Views } from 'react-big-calendar';

export const getViewDateRange = (currentDate: Date, view: string) => {
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    if (view === Views.WEEK) {
        // Начало недели (понедельник)
        const dayOfWeek = startDate.getDay();
        const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Перенос на понедельник
        startDate.setDate(startDate.getDate() + diff);

        // Конец недели (воскресенье)
        endDate.setDate(startDate.getDate() + 6);
    } else if (view === Views.MONTH) {
        // Первый день месяца
        startDate.setDate(1);
        // Последний день месяца
        endDate.setMonth(startDate.getMonth() + 1);
        endDate.setDate(0);
    }

    return { startDate, endDate };
};
