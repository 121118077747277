import React, { FC, useEffect, useState } from "react";
import moment from "moment-timezone";
import MySelect from "../../UI/MySelect/MySelect";
import { useNavigate, useParams } from "react-router-dom";
import { formatGroups } from "./helpers/formatGroup";
import TrainingManagerService, { CreateTrainigDto, EditTrainigDto } from "./api/TrainingManagerService";
import { formatLocations } from "./helpers/formatLocations";
import HeaderButton from "../../UI/HeaderButton/HeaderButton";
import CreateTrainingIcon from "../../assets/images/commit-training.svg";
import EditTrainingIcon from "../../assets/images/edit-training.svg";
import DeleteBin from "../../assets/images/delete-bin.svg";
import MyInput from "../../UI/MyInput/MyInput";
import styles from "./TrainingManager.module.css";
import PopUpMenu from "../../UI/PopUpMenu/PopUpMenu";
import PageLayout from "../../layouts/PageLayout/PageLayout";
import { isSameDay } from "./helpers/isSameDay";
import { IApliacation } from "../../models/IAplication";
import { Link } from "react-router-dom";
import { SelectOption } from "../../models/SelectOption";
import SearchPlayerDropdown from "./components/searchPlayerDrowDown/searchPlayerDrowDown";
import { useAuthStore } from "../../app/store/auth";
import useErrorStore from "../../components/Errors/store/error";
import deleteIcon from '../../assets/images/delete-bin.svg'
import UsersService from "../../api/PlayerService";
import { playerTypes } from "../../constants/playerTypes";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import MyTimePicker from "../../UI/MyTimePicker/MyTimePicker";
import MyDateTimePicker from "../../UI/MyTimePicker/MyTimePicker";
interface TrainingManagerProps {
  action: "create" | "edit";
}

const TrainingManager: FC<TrainingManagerProps> = ({ action }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    repeat: "",
    startTime: "",
    endTime: "",
    location: "",
    group: "",
    id: 0,
    trainerId: 0,
    adminComment: ''
  });

  const [repeatOptions] = useState<SelectOption[]>([
    { value: "1", label: "Jeden Tag" },
    { value: "2", label: "Jede Woche" },
    { value: "3", label: "Jeden Monat" },
    { value: "4", label: "Einmalig" },
  ]);

  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([]);
  const [trainers, setTrainers] = useState<SelectOption[]>([]);



  const fetchTrainers = async () => {
    try {
      const { data } = await UsersService.searchUsers('', 'trainer');
      const trainers: SelectOption[] = data.players.map(trainer => {
        return {
          value: String(trainer.id), label: trainer.username
        }
      })

      setTrainers(trainers)
    } catch (error: any) {
      setError(error)
    }
  }


  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const popUpDeleteOptions = [
    { value: "1", label: "Einmal löschen" },
    { value: "2", label: "Alle löschen" }
  ]
  const popUpUpdateOptions = [
    { value: "1", label: "Einmal aktualisieren" },
    { value: "2", label: "Alle aktualisieren" }
  ]
  const popUpAddToTrainingOptions = [
    { value: "1", label: "Für ein Training registrieren" },
    { value: "2", label: "Alle Trainings registrieren" }
  ]

  const popUpRemoveFromTrainingOptions = [
    { value: "1", label: "Von einem Training entfernen" },
    { value: "2", label: "Von allen Trainings entfernen" }
  ];


  const [answer, setAnswer] = useState({ value: '', reason: '' })
  const [editType, setEditType] = useState<string>('');
  const [addApplicationType, setAddApplicationType] = useState<string>('');
  const [deleteApplicationType, setDeleteApplicationType] = useState<string>('');

  const [deleteType, setDelteType] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);
  const [showAddAppMenu, setShowAddAppMenu] = useState<boolean>(false);
  const [showDleteAppMenu, setShowDleteAppMenu] = useState<boolean>(false);

  const [showDeleteMenu, setShowDeleteMenu] = useState<boolean>(false);
  const [applications, setApplications] = useState<IApliacation[]>([]);


  const fetchGroups = async () => {
    try {
      const { data } = await TrainingManagerService.getGroup();
      setGroupOptions(formatGroups(data));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchLocations = async () => {
    try {
      const { data } = await TrainingManagerService.getLocations();
      setLocationOptions(formatLocations(data));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTrainingById = async () => {
    try {
      const { data } = await TrainingManagerService.getTrainingById(id!);
      console.log(data)
      setApplications(data.applications)

      const berlinStartDate = moment
        .tz(data.startTime, "Europe/Berlin")
        .format("YYYY-MM-DDTHH:mm");
      const berlinEndDate = moment
        .tz(data.endTime, "Europe/Berlin")
        .format("YYYY-MM-DDTHH:mm");

      setFormData({
        repeat: String(data.repeatType),
        startTime: berlinStartDate,
        endTime: berlinEndDate,
        location: String(data.location.id),
        group: String(data.group.id),
        id: data.id,
        trainerId: data.trainerId,
        adminComment: data.adminComment
      });

      setSelectedTrainer(String(data.trainerId))
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchLocations();
    fetchTrainingById();
    fetchTrainers()
  }, []);

  const [errors, setErrors] = useState({
    repeat: false,
    startTime: false,
    endTime: false,
    timeMismatch: false,
    location: false,
    group: false,
  });


  const handleInputChange = (name: string, value: Date | string | null) => {
    let formattedValue = value;

    if (value instanceof Date) {
      formattedValue = moment(value).format("YYYY-MM-DDTHH:mm"); // Преобразуем Date в string
    }

    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [name]: formattedValue };

      if (errors[name as keyof typeof errors]) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      }

      if (name === "startTime" || name === "endTime") {
        const isSame = isSameDay(updatedFormData.startTime, updatedFormData.endTime);
        setErrors((prevErrors) => ({ ...prevErrors, timeMismatch: !isSame }));
      }

      return updatedFormData;
    });
  };



  const validateFields = () => {
    const newErrors = {
      repeat: !formData.repeat,
      startTime: !formData.startTime,
      endTime: !formData.endTime,
      location: !formData.location,
      group: !formData.group,
      timeMismatch: !isSameDay(formData.startTime, formData.endTime)
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };




  const createTraining = async () => {
    if (validateFields()) {
      const berlinStartDate = moment.tz(formData.startTime, "Europe/Berlin").format();
      const berlinEndDate = moment.tz(formData.endTime, "Europe/Berlin").format();
      try {
        const data: CreateTrainigDto = {
          startTime: berlinStartDate,
          endTime: berlinEndDate,
          groupId: formData.group,
          locationId: formData.location,
          repeat_type: parseInt(formData.repeat),
          adminComment: formData.adminComment,
          trainerId: Number(selectedTrainer)
        }

        await TrainingManagerService.postTraining(data);

        navigate("/trainigs");
      } catch (error) {
        console.error(error);
      }
    }
  };
  const [actionTypeError, setActionTypeArror] = useState<boolean>(false);

  const editTraining = async () => {
    if (editType.trim() == '') {
      setActionTypeArror(true)
      return;
    }
    if (validateFields()) {
      const berlinStartDate = moment.tz(formData.startTime, "Europe/Berlin").format();
      const berlinEndDate = moment.tz(formData.endTime, "Europe/Berlin").format();
      const data: EditTrainigDto = {
        id: parseInt(id!),
        startTime: berlinStartDate,
        endTime: berlinEndDate,
        trainerId: Number(selectedTrainer),
        adminComment: formData.adminComment
      }
      try {
        if (editType === '2') {
          await TrainingManagerService.editTrainingAll(data);
        } else if (editType === '1') {
          await TrainingManagerService.editTraining(data);
        }
        navigate("/trainigs");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteTraining = async () => {
    if (deleteType.trim() == '') {
      setActionTypeArror(true)
      return;
    }

    try {
      if (deleteType == "1") {
        await TrainingManagerService.deleteTraining(id!, reason);
      } else if (deleteType == "2") {
        await TrainingManagerService.deleteAllTrainings(id!, reason);
      }

      setShowDeleteMenu(false)
      navigate("/trainigs");
    } catch (e) {
      console.error(e);
    }

  };

  const setError = useErrorStore(store => store.setError)

  const role = useAuthStore(store => store.role)


  const handlePresentChange = async (applicationId: number) => {
    try {
      const { data } = await TrainingManagerService.changePrasent(applicationId);
      const newApplications = applications.map(application => {
        if (application.id == applicationId) {
          return {
            ...application,
            isPresent: data.isPresent
          }
        } else return application
      })

      setApplications(newApplications)
    } catch (error: any) {
      setError(error);
    }
  };


  const [playerToAppId, setPlayerToAppId] = useState<number>(0)
  const handlePlayerSelect = async (playerId: number) => {
    setShowAddAppMenu(true)
    setPlayerToAppId(playerId)
  }


  const hadnleAddApplcaion = async () => {
    console.log(addApplicationType)
    try {
      if (addApplicationType.trim() == '') {
        setActionTypeArror(true)
        return;
      }
      if (addApplicationType == '1') {

        await TrainingManagerService.addPlayerToTraining(Number(id), playerToAppId);
        setShowAddAppMenu(false)
        await fetchTrainingById();
      }

      if (addApplicationType == '2') {
        await TrainingManagerService.addPlayerToAllTraining(formData.id, playerToAppId)
        setShowAddAppMenu(false)
        await fetchTrainingById();
      }
    } catch (error: any) {
      console.log(error)
      setError(error)
    }
  }
  const [deleteApplicationId, setDeleteApplcationId] = useState<number>(0);

  const handleDeleteApplication = (applicationId: number, playerId: number) => {
    setDeleteApplcationId(applicationId)
    setPlayerToAppId(playerId)
    setShowDleteAppMenu(true)
  }


  const hadnleDeleteApplcaion = async () => {
    try {
      if (deleteApplicationType.trim() == '') {
        setActionTypeArror(true)
        return;
      }
      if (deleteApplicationType == '1') {

        await TrainingManagerService.deletePlayerApplication(deleteApplicationId);
        setShowDleteAppMenu(false)
        await fetchTrainingById();
      }

      if (deleteApplicationType == '2') {
        await TrainingManagerService.deleteAllPlayerApplication(formData.id, playerToAppId)
        setShowDleteAppMenu(false)
        await fetchTrainingById();
      }
    } catch (error: any) {
      setError(error)
    }
  }

  const width = useWindowWidth();


  const navigateToUser = (event: any, id: number) => {
    event.stopPropagation();
    navigate('/player/' + id)
  }

  useEffect(() => {
    console.log(formData.endTime)
    if (formData.endTime) { }
  }, [formData.startTime])
  return (
    <PageLayout
    isScroll
      title={action == 'edit' ? "Training bearbeiten" : "Training hinzufügen"}
      headereComopnent={
        <>
          {role == 'admin' &&
            <div className={styles.buttonRow}>
              {action === "edit" ? (
                <>
                  <HeaderButton
                    className="delete"
                    icon={DeleteBin}
                    onClick={() => setShowDeleteMenu(true)}
                  />
                  <HeaderButton
                    icon={EditTrainingIcon}
                    onClick={() => setShowEditMenu(true)}
                  />
                </>
              ) : (
                <HeaderButton icon={CreateTrainingIcon} onClick={createTraining} />
              )}
            </div>
          }
        </>
      }
    >
      <div className={styles.addTrainingPage}>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Wiederholungstyp</div>
            <MySelect
              options={repeatOptions}
              onChange={(value) => handleInputChange("repeat", value)}
              placeholder="Wiederholungstyp"
              value={formData.repeat}
              disabled={action === "edit"}
              className={styles.select}
              hasError={errors.repeat}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Trainer</div>
            <MySelect
              options={trainers}
              onChange={(value) => setSelectedTrainer(value)}
              placeholder="Trainer"
              value={selectedTrainer}
              disabled={role !== "admin"}
              className={styles.select}
              hasError={errors.group}

            />
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Trainingsbeginn</div>
            <MyDateTimePicker
              value={formData.startTime ? new Date(formData.startTime) : null} // Преобразуем строку в Date
              setValue={(value) => handleInputChange("startTime", value)}
              className={styles.input}
              hasError={errors.startTime}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Trainingsende</div>
            <MyDateTimePicker
              value={formData.endTime ? new Date(formData.endTime) : null}
              setValue={(value) => handleInputChange("endTime", value)}
              className={styles.input}
              hasError={errors.endTime}
            />
            {errors.timeMismatch &&
              <span className={styles.inputError}>Der Anfang und das Ende müssen am selben Tag liegen</span>
            }
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Ort</div>
            <MySelect
              options={locationOptions}
              onChange={(value) => handleInputChange("location", value)}
              placeholder="Tennisanlage"
              value={formData.location}
              disabled={action === "edit"}
              className={styles.select}
              hasError={errors.location}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Gruppe</div>
            <MySelect
              options={groupOptions}
              onChange={(value) => handleInputChange("group", value)}
              placeholder="Gruppe"
              value={formData.group}
              disabled={action === "edit"}
              className={styles.select}
              hasError={errors.group}
            />
          </div>

        </div>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Kommentar des Administrators</div>
            <textarea className={`${styles.input} ${styles.textarea}`} value={formData.adminComment} onChange={e => setFormData({ ...formData, adminComment: e.target.value })}
              disabled={role !== 'admin'}
              placeholder='Kommentar des Administrators eingeben'
            ></textarea>
          </div>


        </div>



        {action == 'edit' &&
          <div className={styles.applications}>
            <div className={styles.title}>Anmeldungen:
              {role == 'admin' && <SearchPlayerDropdown onSelectPlayer={handlePlayerSelect} role='player' className={styles.searchPlayer} />}
            </div>
            {applications.length > 0 ?
              <div className={styles.applicationsRow}>{/* TODO декомпозиция блять */}
                {applications.map(application =>
                  <div onClick={() => navigate('/application/' + application.id)} className={styles.application}>
                    <span onClick={event => navigateToUser(event, application.playerId)}>{application.playerName}</span>
                    {width >= 520 && <span>{application.playerPhone}</span>}
                    <span style={{ color: playerTypes.find(type => type.value == application.playerRole)?.color }}>{playerTypes.find(type => type.value == application.playerRole)?.label}</span>
                    <input
                      type="checkbox"
                      checked={application.isPresent}
                      onChange={() => handlePresentChange(application.id)}
                      onClick={event => event.stopPropagation()}
                      className={styles.checkbox}
                    />
                    {role == 'admin' && <img src={deleteIcon} onClick={event => { handleDeleteApplication(application.id, application.playerId); event.stopPropagation() }} className={styles.deleteIcon} />}

                  </div>
                )}
              </div> : <span>Noch keine Anmeldungen</span>
            }
          </div>
        }

      </div>

      <PopUpMenu
        title="Spieler zum Training hinzufügen"
        submitButtonTitle="Speichern"
        closeButtonTitle="Zurück"
        onSubmit={hadnleAddApplcaion}
        show={showAddAppMenu}
        setShow={setShowAddAppMenu}

      >

        <MySelect
          options={popUpAddToTrainingOptions}
          onChange={setAddApplicationType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError={actionTypeError}
        />


      </PopUpMenu>

      <PopUpMenu
        title="Spieler vom Training entfernen"
        submitButtonTitle="Speichern"
        closeButtonTitle="Zurück"
        onSubmit={hadnleDeleteApplcaion}
        show={showDleteAppMenu}
        setShow={setShowDleteAppMenu}

      >

        <MySelect
          options={popUpRemoveFromTrainingOptions}
          onChange={setDeleteApplicationType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError={actionTypeError}
        />


      </PopUpMenu>

      <PopUpMenu
        title="Training löschen"
        submitButtonTitle="Löschen"
        closeButtonTitle="Zurück"
        onSubmit={deleteTraining}
        show={showDeleteMenu}
        setShow={setShowDeleteMenu}
      >

        <MySelect
          options={popUpDeleteOptions}
          onChange={setDelteType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError={actionTypeError}
        />

        <textarea
          placeholder="Löschgrund"
          value={reason}
          onChange={event => setReason(event.target.value)}
          className={`${styles.reason}`}
        />

      </PopUpMenu>

      <PopUpMenu
        title="Training bearbeiten"
        submitButtonTitle="Speichern"
        closeButtonTitle="Zurück"
        onSubmit={editTraining}
        show={showEditMenu}
        setShow={setShowEditMenu}

      >

        <MySelect
          options={popUpUpdateOptions}
          onChange={setEditType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError={actionTypeError}
        />


      </PopUpMenu>




    </PageLayout>
  );
};

export default TrainingManager;
