import { MyEvent } from "../../../components/MyCalendar/components/CustomEvent/CustomEvent";
import { formatTimeRange } from "../../../helpers/formatTimeRange";
import { truncateString } from "../../../helpers/truncateString";
import { ITraining } from "../../../models/ITraining";
import moment from "moment"; // Импортируем moment

export const formatTrainigsToEvents = (trainigs: ITraining[]): MyEvent[] => {
    return trainigs.map(training => {
        return {
            id: training.trainingDatesId,
            //title: `${(training.group.groupName)} -  ${(training.location.locationName)}`,
            row1: (training.group.groupName),
            row2: (training.location.locationName),
            start: moment(training.startTime).toDate(), // Используем moment для создания объекта Date
            end: moment(training.endTime).toDate(),     // Используем moment для создания объекта Date
            color: training.trainer?.color || '#ff0000',
            date: formatTimeRange(String(training.startTime), String(training.endTime)),
            applicationCount: training.applicationCount
        }
    });
};
