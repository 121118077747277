import React, { useEffect, useState } from 'react';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import MyCalendarNav from '../../components/MyCalendarNav/MyCalendarNav';
import HeaderButton from '../../UI/HeaderButton/HeaderButton';
import { RouteNames } from '../../app/router';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { MyEvent } from '../../components/MyCalendar/components/CustomEvent/CustomEvent';
import CalendarService from './api/CalendarService';
import { formatTrainigsToEvents } from './helpers/formatTrainigsToEvents';
import addTrainingIcon from '../../assets/images/add-training.svg';
import { View, Views } from 'react-big-calendar';
import MySelect from '../../UI/MySelect/MySelect';
import styles from './Calendar.module.css';
import { formatApplicationsToEvents } from './helpers/formatAplicationsToEvents';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import MyCalendar from '../../components/MyCalendar/MyCalendar';
import { CalendarPage, useCalendarStore } from './store/calendarPage';
import { useAuthStore } from '../../app/store/auth';
import { SelectOption } from '../../models/SelectOption';
import { getViewDateRange } from './helpers/getViewDateRange';
import { ITraining } from '../../models/ITraining';

const Calendar = () => {
    const [view, setView] = useState<View>(Views.WEEK); // Управление видом (месяц или неделя)
    const currentDate = useCalendarStore(store => store.currentDate);
    const setCurrentDate = useCalendarStore(store => store.setCurrentDate);
    const [events, setEvents] = useState<MyEvent[]>([]);
    const setCalendarPage = useCalendarStore(store => store.setCalendarPage);
    const calendarPage = useCalendarStore(store => store.calendarPage);
    const isMobileVersion = useCalendarStore(store => store.isMobileVersion);
    const isCalendarMobile = useCalendarStore(store => store.isCalendarMobile);
    const setIsCalendarMobile = useCalendarStore(store => store.setIsCalendarMobile);
    const role = useAuthStore(store => store.role);
    const navigate = useNavigate();

    // Состояние для отслеживания координат свайпа
    const [touchStart, setTouchStart] = useState<{ x: number; y: number } | null>(null);
    const [touchEnd, setTouchEnd] = useState<{ x: number; y: number } | null>(null);


    const calendarOptions: SelectOption[] = [
        { value: 'Trainings', label: 'Trainings' },
        { value: 'Anmeldungen', label: 'Anmeldungen' },
    ];
    const viewOptions: SelectOption[] = [
        { value: Views.MONTH, label: 'Monat' },
        { value: Views.WEEK, label: 'Woche' },
    ];

    const handleAddTraining = () => {
        navigate(RouteNames.ADD_TRAININGS);
    };

    const onEventClick = (event: MyEvent) => {
        navigate(`/edit-training/${event.id}`);
    };

    const userId = useAuthStore(store=> store.userId)
    const { startDate, endDate } = getViewDateRange(currentDate, view);
    console.log({startDate, endDate})
    const [initialEvents, setInitialIvents] = useState<ITraining[]>([])
    const fetchTrainigs = async () => {
        try {
            const getFunc = role == 'trainer' ? CalendarService.getTrainings(startDate, endDate,userId) : CalendarService.getTrainings(startDate, endDate)
            const { data } = await getFunc;
            setInitialIvents(data)
            const newEvents = formatTrainigsToEvents(data);
            if(view == 'week'){
                setEvents(newEvents.map(event => ({...event, date: '' })));
            }else setEvents(newEvents);
        
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (calendarPage === 'Trainings') {
            fetchTrainigs();
        } 
    }, [calendarPage, currentDate,view]);

  

    const handleCalendarTypeSelect = (value: string) => {
        const option = calendarOptions.find(option => option.value === value);
        if (option) setCalendarPage(option.label as CalendarPage);
    };

    const handleViewSelect = (value: string) => {
        localStorage.setItem('view', value);
        setView(value as View);
    };

    const handleNavigate = (date: Date) => {
        const newDate = new Date(date.setHours(0, 0, 0, 0));
        setCurrentDate(newDate);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        const { screenX, screenY } = e.changedTouches[0];
        setTouchStart({ x: screenX, y: screenY });
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        const { screenX, screenY } = e.changedTouches[0];
        setTouchEnd({ x: screenX, y: screenY });

        if (touchStart) {
            const deltaX = screenX - touchStart.x;
            const deltaY = screenY - touchStart.y;

            const threshold = 30; // Минимальное расстояние по X для свайпа
            const verticalLimit = 20; // Максимальное вертикальное отклонение
            if (Math.abs(deltaX) > threshold && Math.abs(deltaY) < verticalLimit) {
                const newDate = new Date(currentDate);
                
                if (deltaX < 0) {
                    // Свайп влево — вперед на неделю
                    newDate.setDate(newDate.getDate() + 7);
                } else {
                    // Свайп вправо — назад на неделю
                    newDate.setDate(newDate.getDate() - 7);
                }
            
                setCurrentDate(newDate);
            }
            
        }
    };

    const width = useWindowWidth();
    const handleMobileCalendarVersion = () => {
        width <= 700 && setIsCalendarMobile(!isCalendarMobile);
    };

    

    return (
        <PageLayout
        isScroll ={isCalendarMobile}
            title={width > 940 ? 'Kalender' : ''}
            headereComopnent={
                <>
                    <MyCalendarNav isMobileVersion={isMobileVersion} view={view} currentDate={currentDate} setCurrentDate={handleNavigate} />
                    <div className={styles.headerButtonRow}>
                        {role == 'admin' && false&&
                        
                             <MySelect
                            options={calendarOptions}
                            onChange={handleCalendarTypeSelect}
                            value={calendarPage}
                            className={styles.selectPage}
                        />
                        }
                   
                        {!isMobileVersion && (
                            <MySelect
                                options={viewOptions}
                                onChange={handleViewSelect}
                                value={view}
                                className={`${styles.selectPage} ${styles.selectView}`}
                            />
                        )}
                        {role == 'admin' && calendarPage === 'Trainings' && !isMobileVersion && <HeaderButton icon={addTrainingIcon} onClick={handleAddTraining} />}
                    </div>
                </>
            }
        >
            <div
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={handleMobileCalendarVersion}
                className={styles.main}
            >
                <MyCalendar
                    isCalendarMobile={isCalendarMobile}
                    view={view}
                    currentDate={currentDate}
                    onEventClick={onEventClick}
                    setCurrentDate={handleNavigate}
                    events={events}
                />
                {isMobileVersion && <HeaderButton icon={addTrainingIcon} onClick={handleAddTraining} className={styles.AddMobileIcon} />}
            </div>
        </PageLayout>
    );
};

export default Calendar;
