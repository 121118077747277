import React, { FC, ReactNode, useEffect, useState } from 'react';
import styles from './PageLayout.module.css'
import { useWindowWidth } from '../../hooks/useWindowWidth';
import useAppStore from '../../store/app';
interface PageLayoutProps {
    title?: string;
    children: ReactNode;
    headereComopnent?: ReactNode;
    centerTitle?: boolean;
    isScroll?: boolean;

}

const PageLayout: FC<PageLayoutProps> = ({ title, children, headereComopnent, centerTitle,isScroll}) => {
    const isNavBarShow = useAppStore(store => store.isNavBarShow)
    const setIsNavBarShow = useAppStore(store => store.setIsNavBarShow)

    const width = useWindowWidth();
    useEffect(() => {
        setIsNavBarShow(width >= 580)
    }, [width])
    return (
        <div className={`${styles.page} ${isScroll && styles.scroll} ${isNavBarShow ? styles.open : ''}`}>
            <div className={styles.overlay} onClick={()=> setIsNavBarShow(false)}></div>
            <div className={`${styles.header} ${centerTitle && styles.center}`}>
                <div className={`${styles.burgerIcon} ${isNavBarShow && styles.active}`} onClick={() => setIsNavBarShow(!isNavBarShow)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {title?.trim() !== '' && <div className={styles.title}>{title}</div>}
                {headereComopnent}
            </div>
                {children}
        </div>
    )
}

export default PageLayout
