// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .inputContainer {
    position: relative;
    display: inline-block;
    width: 100%;
  } */
  
  .MyDateTimePicker_input__hwt8T {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
    padding: 8.5px 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }
  
  .MyDateTimePicker_input__hwt8T:focus {
    border-color: #007bff;
  }
  
  .MyDateTimePicker_errorBorder__Vr\\+QN {
    border-color: red;
  }
  
  .MyDateTimePicker_errorText__OZ8el {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/UI/MyTimePicker/MyDateTimePicker.module.css"],"names":[],"mappings":"AAAA;;;;KAIK;;EAEH;IACE,WAAW;IACX,qCAAqC;IACrC,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,eAAe;EACjB","sourcesContent":["/* .inputContainer {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n  } */\n  \n  .input {\n    width: 100%;\n    border: 1px solid rgba(0, 0, 0, 0.38);\n    border-radius: 8px;\n    padding: 8.5px 14px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n  }\n  \n  .input:focus {\n    border-color: #007bff;\n  }\n  \n  .errorBorder {\n    border-color: red;\n  }\n  \n  .errorText {\n    color: red;\n    font-size: 12px;\n    margin-top: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `MyDateTimePicker_input__hwt8T`,
	"errorBorder": `MyDateTimePicker_errorBorder__Vr+QN`,
	"errorText": `MyDateTimePicker_errorText__OZ8el`
};
export default ___CSS_LOADER_EXPORT___;
