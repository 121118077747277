// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_navBar__uWrUQ {
    height: 100vh;
    width: 75px;
    background: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    padding: 10px 0;
}

.NavBar_navButton__Kx7Lz {

    width: 55px;
    height: 55px;
    background: inherit;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavBar_navIcon__MZpQz {

    width: 31px;
    height: 31px;
}

.NavBar_navButton__Kx7Lz.NavBar_active__rSStc {
    background: #262626;
    border-radius: 10px;
}

@media(max-width: 580px) {
    .NavBar_navBar__uWrUQ {
        position: absolute;
        left: -100%;
    }
}

.NavBar_open__WoJst {
    left: 0;
}

.NavBar_logout__j-Q46 {
    margin-top: auto;
}`, "",{"version":3,"sources":["webpack://./src/layouts/NavBar/NavBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI;QACI,kBAAkB;QAClB,WAAW;IACf;AACJ;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".navBar {\n    height: 100vh;\n    width: 75px;\n    background: #333333;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    row-gap: 25px;\n    padding: 10px 0;\n}\n\n.navButton {\n\n    width: 55px;\n    height: 55px;\n    background: inherit;\n    border: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.navIcon {\n\n    width: 31px;\n    height: 31px;\n}\n\n.navButton.active {\n    background: #262626;\n    border-radius: 10px;\n}\n\n@media(max-width: 580px) {\n    .navBar {\n        position: absolute;\n        left: -100%;\n    }\n}\n\n.open {\n    left: 0;\n}\n\n.logout {\n    margin-top: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": `NavBar_navBar__uWrUQ`,
	"navButton": `NavBar_navButton__Kx7Lz`,
	"navIcon": `NavBar_navIcon__MZpQz`,
	"active": `NavBar_active__rSStc`,
	"open": `NavBar_open__WoJst`,
	"logout": `NavBar_logout__j-Q46`
};
export default ___CSS_LOADER_EXPORT___;
