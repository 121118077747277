import { SelectOption } from "../models/SelectOption";

export const playerTypes: SelectOption[] = [
  { label: "Neuer Spieler", value: "newPlayer", color: '#3498db' },
  { label: "Reguläre Spieler", value: "regularPlayer", color: '#2ecc71' },
  { value: "trainer", label: "Trainer", color: '#f1c40f' },
  { value: "trialMonth", label: "Testmonat", color: '#e67e22' },
  { value: "documentVerification", label: "Dokumentenüberprüfung", color: '#e74c3c' },
  { value: "testmonatIsOver", label: "Testmonat ist beendet", color: 'red' }
];

