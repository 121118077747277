import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import AppliacationService from './api/ApplicationService';
import MySelect from '../../UI/MySelect/MySelect';
import MyInput from '../../UI/MyInput/MyInput';
import styles from './Application.module.css';
import TrainingManagerService from '../../modules/TrainigManager/api/TrainingManagerService';
import { formatLocations } from '../../modules/TrainigManager/helpers/formatLocations';
import { formatGroups } from '../../modules/TrainigManager/helpers/formatGroup';
import moment from 'moment';
import { SelectOption } from '../../models/SelectOption';
import MyDateTimePicker from '../../UI/MyTimePicker/MyTimePicker';

const Application = () => {
    const { id } = useParams();
    const [username, setUsername] = useState<string>('');
    const [playerPhone, setPlayerPhone] = useState<string>('');
    const [playerComment, setPlayerComment] = useState<string>('');
    const [groupId, setGroupId] = useState<SelectOption | null>(null);
    const [locationId, setLocationId] = useState<SelectOption | null>(null);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
    const [groupOptions, setGroupOptions] = useState<SelectOption[]>([]);

    const fetchGroups = async () => {
        try {
            const { data } = await TrainingManagerService.getGroup();
            setGroupOptions(formatGroups(data));
        } catch (e) {
            console.error(e);
        }
    };

    const fetchLocations = async () => {
        try {
            const { data } = await TrainingManagerService.getLocations();
            setLocationOptions(formatLocations(data));
        } catch (e) {
            console.error(e);
        }
    };

    const fetchApplication = async () => {
        try {
            const { data } = await AppliacationService.getApplication(Number(id));
            console.log(data);

            setUsername(data.playerName);
            setStartDate(moment.tz(data.startDate, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm'));
            setEndDate(moment.tz(data.endDate, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm'));
            setPlayerPhone(data.playerPhone!);
            setPlayerComment(data.playerComment!);

            setGroupId({
                value: String(data.group.id),
                label: data.group.groupName
            });

            setLocationId({
                value: String(data.location.id),
                label: data.location.locationName
            });
        }
        catch (e) {
            console.error(e)
        }
    };

    useEffect(() => {
        fetchApplication();
        fetchGroups();
        fetchLocations();
    }, [id]);

    return (
        <PageLayout title={`Anmeldung von ${username}`} isScroll>
            <div className={styles.ApplicationPage}>
                <div className={styles.group}>
                    <div className={styles.title}>Spielername</div>
                    <MyInput
                        value={username}
                        setValue={setUsername}
                        type="text"
                        placeholder="Spielername"
                        className={styles.input}
                        disabled
                    />
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Gruppe</div>
                    <MySelect
                        options={groupOptions}
                        onChange={(value) => setGroupId({ value, label: groupId?.label || '' })}
                        value={groupId?.value}
                        className={styles.applicationSelect}
                        disabled
                    />

                   
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Trainingsdatum</div>
                    <MyInput
                        value={startDate}
                        setValue={setStartDate}
                        type="datetime-local"
                        className={styles.input}

                        disabled
                    />
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Telefonnummer</div>
                    <MyInput
                        value={playerPhone}
                        setValue={setPlayerPhone}
                        type="tel"
                        placeholder="Telefonnummer"
                        className={styles.input}
                        disabled
                    />
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Ort</div>
                    <MySelect
                        options={locationOptions}
                        onChange={(value) => setLocationId({ value, label: locationId?.label || '' })}
                        value={locationId?.value}
                        className={styles.applicationSelect}
                        disabled
                    />
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Antragsdatum</div>
                    <MyInput
                        value={endDate}
                        setValue={setEndDate}
                        type="datetime-local"
                        className={styles.input}
                        disabled
                    />
                </div>
                <div className={styles.group}>
                    <div className={styles.title}>Kommentar</div>
                    <textarea
                        className={styles.textarea}
                        value={playerComment}
                        onChange={(e) => setPlayerComment(e.target.value)}
                        disabled>
                    </textarea>
                </div>
            </div>
        </PageLayout>
    );
};

export default Application;
