import React, { FC, useMemo } from 'react';
import { Calendar, DateLocalizer, Formats, NavigateAction, View } from 'react-big-calendar';
import CustomEvent, { MyEvent } from '../CustomEvent/CustomEvent';
import eventPropGetter from '../../components/eventPropGetter';
import 'moment/locale/de'; // Импорт немецкой локализации, если используется moment

interface DesktopCalendarProps {
    filteredEvents: MyEvent[];
    localizer: DateLocalizer;
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    onEventClick: (calendarEvent: MyEvent) => void;
}

// Форматы с учетом немецкого языка
const formats: Formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, _, localizer) =>
        //@ts-ignore
        `${localizer.format(start, 'HH:mm')} - ${localizer.format(end, 'HH:mm')}`,
    agendaTimeFormat: 'HH:mm',
    dayHeaderFormat: 'dddd, DD.MM.YYYY',
    dayRangeHeaderFormat: ({ start, end }, _, localizer) =>
        //@ts-ignore
        `${localizer.format(start, 'DD.MM.YYYY')} - ${localizer.format(end, 'DD.MM.YYYY')}`,
    monthHeaderFormat: 'MMMM YYYY',
};

const DesktopCalendar: FC<DesktopCalendarProps> = ({
    localizer,
    filteredEvents,
    view,
    currentDate,
    setCurrentDate,
    onEventClick,
}) => {
    const dayPropGetter = (date: Date) => {
        return {
            className: 'rbc-day-slot',
        };
    };

    const handleNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        if (action === 'DATE') {
            return;
        }
        setCurrentDate(newDate);
    };

    // Вычисляем минимальное время начала и максимальное время окончания среди событий
    const eventStartHours = filteredEvents
        .map(event => event.start ? new Date(event.start).getHours() : null)
        .filter(hour => hour !== null) as number[]; // Убираем undefined значения

    const eventEndHours = filteredEvents
        .map(event => event.end ? new Date(event.end).getHours() : null)
        .filter(hour => hour !== null) as number[]; // Убираем undefined значения
   
    const minHour = Math.max(Math.min(...eventStartHours, 10) -2 , 0); 
    const maxHour = Math.min(Math.max(...eventEndHours, 22) + 2, 23); 

    const minTime = new Date(currentDate);
    minTime.setHours(minHour, 0, 0, 0);
    
    const maxTime = new Date(currentDate);
    maxTime.setHours(maxHour, 0, 0, 0);

    return (
        <div style={{ height: '100vh' }}>
            <Calendar<MyEvent>
                localizer={localizer}
                events={filteredEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '90%' }}
                components={{
                    toolbar: () => null,
                    event: CustomEvent,
                }}
                dayPropGetter={dayPropGetter}
                view={view}
                date={currentDate}
                onNavigate={handleNavigate}
                eventPropGetter={eventPropGetter}
                onSelectEvent={(calendarEvent) => onEventClick(calendarEvent)}
                formats={formats}
                min={minTime} // Устанавливаем начальное время в календаре
                max={maxTime} // Устанавливаем конечное время в календаре
            />
        </div>
    );
};

export default DesktopCalendar;
