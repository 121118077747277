import { AxiosResponse } from "axios";
import { IUser } from "../../../models/IUser";
import $api from "../../../app/api/http";
import { IApliacation } from "../../../models/IAplication";

export type СreateUserDto = Omit<IUser, 'id'>;

interface CreateUserRes{
    id:number;
    password:string;
    username: string
}

interface ApplicationsRes {
    data: IApliacation[];
    total: number
}
export default class PlayerService {
    static async getUser(userId:number):Promise<AxiosResponse<IUser>>{
        return $api.get(`user/one/${userId}`);
    }

    static async getUserApplications(userId:number,page:number,itemsPerPage: number):Promise<AxiosResponse<ApplicationsRes>>{
        return $api.get(`applications/user/${userId}?page=${page}&limit=${itemsPerPage}`);
    }

    static async editUser(dto: IUser ):Promise<AxiosResponse<IUser>>{
        return $api.put(`/user`,dto);
    }

    static async createUser(dto: СreateUserDto ):Promise<AxiosResponse<CreateUserRes>>{
        return $api.post(`/user/create`,dto);
    }

    static async createTrainer(dto: СreateUserDto ):Promise<AxiosResponse<CreateUserRes>>{
        return $api.post(`/user/trainer`,dto);
    }

    static async deleteUser(userId:number ):Promise<AxiosResponse<CreateUserRes>>{
        return $api.delete(`/user/${userId}`);
    }

    static async changePassword(password:string ,userId:number):Promise<AxiosResponse<CreateUserRes>>{
        return $api.put(`/user/chagePassword`, {
            password, userId
        });
    }

    static async confirmDocument(userId:number):Promise<AxiosResponse>{
        return $api.put(`/user/documents/confirm/${userId}`);
    }
    


}