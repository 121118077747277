import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Event as BigCalendarEvent } from 'react-big-calendar';
import styles from './CustomEvent.module.css'
export interface MyEvent extends BigCalendarEvent {
  color?: string; // Добавляем поле color
  date: string;
  id: number,
  row1?: string,
  row2?: string,
  applicationCount: number
}

const CustomEvent: React.FC<EventProps<MyEvent>> = ({ event: customEevent }) => {

  return (
    <div className={styles.event} style={{ backgroundColor: customEevent.color || '#3174ad', padding: '5px', borderRadius: '4px', color: 'white' }}>
      {typeof customEevent?.title === 'string' && customEevent.title.trim() !== '' ? (
        <div className={styles.title}>{customEevent.title} </div>
      )
        :
        <>
          <div className={styles.title}>{customEevent.row1} </div>
          <div className={styles.title}>{customEevent.row2} </div>

        </>

      }
      {/*   <div className={styles.title}>{customEevent.title} </div> */}

      <div className={styles.date}><span>{customEevent.date}</span> </div>
      <div className={styles.date}>
      
        <span className={styles.applicationCount}>{customEevent.applicationCount}&nbsp;&nbsp;   </span>Teilnehmer
      </div>


    </div>
  );
};

export default CustomEvent;
