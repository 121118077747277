import React, { FC } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./MyDateTimePicker.module.css";

interface MyDateTimePickerProps {
  value: Date | null;
  setValue: (value: Date | null) => void;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
}

const MyDateTimePicker: FC<MyDateTimePickerProps> = ({
  value,
  setValue,
  placeholder,
  className,
  hasError,
}) => {
  const defaultDate = value || new Date();
  if (!value) {
    defaultDate.setHours(10, 0, 0, 0);
  }

  return (
    <div className={`${styles.inputContainer} ${className}`}>
      <DatePicker
        selected={value || defaultDate}
        onChange={(date) => setValue(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30} // ✅ Интервал времени теперь 30 минут
        dateFormat="dd.MM.yyyy HH:mm"
        placeholderText={placeholder || "Datum und Zeit wählen"}
        className={`${styles.input} ${hasError ? styles.errorBorder : ""}`}
      />
      {hasError && (
        <span className={styles.errorText}>
          Eingabefehler. Bitte überprüfen Sie die Daten.
        </span>
      )}
    </div>
  );
};

export default MyDateTimePicker;
