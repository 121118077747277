import { AxiosResponse } from "axios";
import { IUser } from "../models/IUser";
import $api from "../app/api/http";
interface searchUsersRes {
    players: IUser[];
    totalPages: number
}
export default class UsersService {
    static async searchUsers(searchQuery:String,userType:string,page: number = 1, limit: number = 15):Promise<AxiosResponse<searchUsersRes>>{

        return await $api.get(`user/search-players?searchQuery=${searchQuery}&role=${userType}&page=${page}&limit=${limit}`);
    }

}