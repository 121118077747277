import React, { useEffect, useState } from 'react'
import styles from './NavBar.module.css';
import logo from '../../assets/images/logo.png'
import userIcon from '../../assets/images/users-icon.png'
import navCalendarIcon from '../../assets/images/nav-calendar.svg'
import navListIcon from '../../assets/images/nav-list.svg';
import { Link, useLocation } from 'react-router-dom';
import { RouteNames } from '../../app/router';
import NavDropDownBtn, { DropDownListItem } from '../../UI/NavDropDownBtn/NavDropDownBtn';
import { link } from 'fs';
import useAppStore from '../../store/app';
import { useAuthStore } from '../../app/store/auth';
const NavBar = () => {
    const isNavBarShow = useAppStore(store => store.isNavBarShow)
    const logout = useAuthStore(store => store.logout)
    const role = useAuthStore(store => store.role)

    const location = useLocation();
    const dropDownList: DropDownListItem[] = [
        {
            title: 'groups',
            link: RouteNames.GROUPS_MANAGER
        },
        {
            title: 'locations',
            link: RouteNames.LOCATIONS_MANAGER
        }
    ]
    return (
        <div className={`${styles.navBar} ${isNavBarShow ? styles.open : ''}`}>
            <img src={logo} alt="" className={styles.logo} />
            <Link to={RouteNames.CALENDAR} className={`${styles.navButton} ${location.pathname == RouteNames.CALENDAR ? styles.active : ''}`}>
                <img src={navCalendarIcon} alt="" className={styles.navIcon} />
            </Link>


            <Link to={RouteNames.PLAYERS} className={`${styles.navButton} ${location.pathname == RouteNames.PLAYERS ? styles.active : ''}`}>
                <img src={userIcon} alt="" className={styles.navIcon} />
            </Link>
            {role == 'admin' && <NavDropDownBtn dropDownList={dropDownList} />}
            <div onClick={logout} className={`${styles.navButton}`}>
                <svg width="31" height="31" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1053_1014)">
                        <path d="M40.916 0.0281524C40.8694 0.024376 40.8299 0 40.7811 0H20.625C17.5238 0 15 2.52376 15 5.625V7.49989C15 8.53501 15.8398 9.37512 16.8749 9.37512C17.91 9.37512 18.7498 8.53501 18.7498 7.49989V5.625C18.7498 4.59194 19.5916 3.75011 20.625 3.75011H29.3605L28.7885 3.94134C27.2701 4.46629 26.25 5.89691 26.25 7.49989V35.6249H20.625C19.5916 35.6249 18.7498 34.783 18.7498 33.75V29.9999C18.7498 28.9651 17.91 28.125 16.8749 28.125C15.8398 28.125 15 28.9651 15 29.9999V33.75C15 36.8512 17.5238 39.375 20.625 39.375H26.25V41.2499C26.25 43.3181 27.9316 45 29.9998 45C30.4012 45 30.7819 44.9419 31.1942 44.8142L42.4593 41.0586C43.9799 40.5337 44.9999 39.1031 44.9999 37.5001V3.75011C44.9999 1.56383 43.1154 -0.150032 40.916 0.0281524Z" fill="white" />
                        <path d="M20.0755 17.4245L12.5756 9.9245C12.0393 9.38828 11.2332 9.22691 10.5318 9.5177C9.83241 9.80813 9.37512 10.4928 9.37512 11.2501V16.8751H1.87489C0.840111 16.8751 0 17.7153 0 18.75C0 19.7851 0.840111 20.6252 1.87489 20.6252H9.37512V26.2502C9.37512 27.0076 9.83241 27.6918 10.5318 27.9826C11.2332 28.2731 12.0393 28.1121 12.5756 27.5757L20.0755 20.0756C20.8088 19.3425 20.8088 18.1578 20.0755 17.4245Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1053_1014">
                            <rect width="45" height="45" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>

        </div>
    )
}

export default NavBar;
