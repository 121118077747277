import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { IUser } from "../../../models/IUser";
interface searchUsersRes {
    players: IUser[];
    totalPages: number
}
export default class UsersService {
     static async searchUsers(searchQuery:String,userType:string,page: number = 1, limit: number = 15):Promise<AxiosResponse<searchUsersRes>>{

        return await $api.get(`user/search-players?searchQuery=${searchQuery}&role=${userType}&page=${page}&limit=${limit}`);
    }

    static async convertPlayerState(userId:number):Promise<AxiosResponse<IUser>>{
        return await $api.put(`user/convert/new-to-regular/${userId}`);
    }
    
}